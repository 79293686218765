import React, { useState } from "react";
import { Button, Row, Col, Modal, Spinner } from "react-bootstrap";
import { Link } from "gatsby";
import ModalCloseButton from "../ModalCloseButton";
import styled from "styled-components";

const RulesList = styled.ul`
  list-style: none;
  padding: 0;
`

const ModalPreApplication = (props) => {
  const { show, handleClose, handleShowModalApplication } = props;

  return (
    <Modal
      {...props}
      size="md"
      centered
      show={show}
      onHide={handleClose}
    >
      <ModalCloseButton onClick={handleClose} />
      
      <Modal.Body className="p-0">
        <div className="pre-apply-modal-main bg-white rounded-8 overflow-hidden">
          <Row className="row">
            <Col className="text-center px-sm-20 py-sm-12 p-10">
              <h2 className="font-family-primary font-size-7">
                Before applying, please note that:
              </h2>
              <div className="my-10">
                <i className="fa fa-clipboard-check font-size-12"></i>
              </div>
              <div className="">
                <RulesList className="check-rules">
                  { 
                    [
                      'You must have completed min. 12 months in your current position (permanent contract) or 75% of your contract (fixed-term contract).',
                      'You must discuss the opportunity with your manager.',
                      'We may contact your current manager to discuss the fit criteria.'
                    ].map((item,i)=>(
                      <li className="pb-7 text-left">
                        <Row className="justify-content-center">
                          <Col className="col-auto px-0">✓</Col>
                          <Col className="col-10">
                            {item}
                          </Col>
                        </Row>
                      </li>
                    ))
                  }
                </RulesList>
              </div>
              <Button 
                variant="primary" 
                onClick={handleShowModalApplication}
                className="text-uppercase"
              >
                I agree, take me to the application
              </Button>
              <Link to="/what-you-need-to-know" className="mt-10">
                Read more about the criteria <i className="fas fa-arrow-right ml-4"></i>
              </Link>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPreApplication;
