import React from "react"
import { Row, Col, Container, Button } from "react-bootstrap"
import { Link, navigate } from "gatsby"

const BackButton = ({text, customLink=null}) => {
    return (
        <>
            <Container className="pt-9">
                <Row className="row justify-content-center">
                    <Col className="col-12">
                        <div className="mb-9">
                            <Button variant="link" className="pl-0 ml-0">
                                { customLink ? 
                                    (<Link 
                                        to={customLink}
                                        className="text-black d-flex text-decoration-none font-weight-bold"
                                    >
                                        <i className="fas fa-arrow-left mr-4"></i> {text || "Back"}
                                    </Link>) : 
                                    (<a 
                                        href="#" 
                                        className="text-black d-flex text-decoration-none font-weight-bold"
                                        onClick={ ()=> navigate(-1) }
                                    >
                                        <i className="fas fa-arrow-left mr-4"></i> {text || "Back"}
                                    </a>)
                                }
                            </Button> 
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default BackButton