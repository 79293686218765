import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import Moment from "moment";
import { Container, Row, Col, Card, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import sanitizeHtml from "sanitize-html";
import parse from "html-react-parser";
import { decode } from "html-entities";
import SEO from "../components/seo";
import ModalApplication from "../components/ModalApplication/ModalApplication";
import BackButton from "../components/Core/BackButton";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ModalPreApplication from "../components/ModalApplication/ModalPreApplication";
import ModalShare from "../components/ModalShare/ModalShare"
import * as copy from 'copy-to-clipboard'
import useEntity  from "../hooks/useEntity"
import useAccount from "../hooks/useAccount"
import { sanitize } from "../utils/content";
import Sticky from "react-stickynode"

const VacancyDetailContainer = styled.div`
  position: relative;

  & .param-dt {
    font-size: 12px;
    color: #797979;
  }  
}
`;

const formatDate = (date) => {
  Moment.locale('en');
  return Moment(date).format('D MMM Y');
}

const VacancyDetails = (
    { data, location }
  ) => {

  const account = useAccount();
  let isLoggedIn = account.isLoggedIn;

  const [showModalApplication, setShowModalApplication] = useState(false);
  const [showModalPreApplication, setShowModalPreApplication] = useState(false);
  const [showModalShare, setShowModalShare] = useState(false);

  const handleCloseModalApplication = () => setShowModalApplication(false);
  const handleShowModalApplication = () => {
    setShowModalApplication(true)
    handleCloseModalPreApplication()
  }

  const handleCloseModalPreApplication = () => setShowModalPreApplication(false);
  const handleShowModalPreApplication = () => setShowModalPreApplication(true);

  const handleCloseModalShare = () => setShowModalShare(false);
  const handleShowModalShare = () => setShowModalShare(true);

  const vacancy = data.sharePointVacanciesList.data.fields;
  const avatar = getImage(data.avatar)
  
  const entity = useEntity(vacancy.Company)

  // Decode, sanitize and parse job description which comes as string from sharepoint
  const JobDescription = parse(
    sanitizeHtml(
      decode(vacancy.JobDescription), 
      { 
        allowedTags: [ 'p', 'b', 'i', 'em', 'strong', 'a', 'h1', 'h2', 'h3', 'ul', 'li', 'blockquote' ], 
        allowedAttributes: {
          'a': [ 'href' ]
        },
      }
    )
  );
  const ShortDescription = sanitize(vacancy.ShortDescription)

  const headerConfig = {
    paddingBottom: 10,
  };
  
  const jobParams = [
      {
          label: "Location", 
          value: vacancy.Office,
          icon: "fas fa-map-marker-alt",
      },
      {
          label: "Experience", 
          value: vacancy.Experience,
          icon: "fas fa-star",
      },
      {
          label: "Working hours", 
          value: vacancy.WorkingHours,
          icon: "fas fa-clock",
      },
      {
          label: "Job Type", 
          value: vacancy.JobType,
          icon: "fas fa-file-contract",
      },
      {
        label: "Job Category",
        value: vacancy.Function,
        icon: "fas fa-tag",
      },
      {
          label: "Posted", 
          value: formatDate(vacancy.Created),
          icon: "far fa-calendar-check",
      }
  ]

  // handle copy email address functionality
  const [showTooltip, setShowTooltip] = useState(false)

    const handleCopyToClipboard = (email, setShowTooltip) => {
        copy(email)
        setShowTooltip(true)
        setTimeout(function() { 
            setShowTooltip(false) 
        }, 2000)
    }

    const ApplyAndShare = () => {
      return (
        <>
          <Button 
            onClick={isLoggedIn ? handleShowModalPreApplication : handleShowModalApplication}
          >
            Apply now
          </Button>
          { 
            isLoggedIn && (<div className="mt-4">
              <Button
                variant="link"
                className="pl-0 ml-0 text-black font-size-4" 
                onClick={handleShowModalShare}
                style={{textTransform:"none"}}
              >
                <i className="fa fa-share mr-5"></i>Share with your network
              </Button>
            </div>)
          }
        </>
      )
    }

  return (
    <>  
      <PageWrapper headerConfig={headerConfig}>
        <ModalPreApplication 
          show={showModalPreApplication}
          handleClose={handleCloseModalPreApplication}
          handleShowModalApplication={handleShowModalApplication}
        />
        <ModalApplication
            vacancy = {vacancy}
            handleClose={handleCloseModalApplication}
            show={showModalApplication}
        />
        <ModalShare
            handleClose={handleCloseModalShare}
            show={showModalShare}
            vacancyUrl={`${process.env.GATSBY_SITE_URL}${location.pathname}`}
            vacancyPosition={vacancy.Position}
            vacancyEntity={vacancy.Company}
        />
        <SEO 
          title={`${vacancy.Position} at ${vacancy.Company}`} 
          description={ShortDescription}
        />
        <VacancyDetailContainer>
          <BackButton text={isLoggedIn ? null : 'Home'} />
          <Container className="">
            <Row className="vacancy-head">
              <Col className="hspacer d-none d-lg-block col-lg-1"></Col>
              <Col className="content col-lg-7">
                <div className="mb-5">
                  <h3 className="font-size-9 mb-0">
                    {vacancy.Position}
                  </h3>
                </div>
                <div className="company-logo mb-8">
                    <Link to={`/${entity.blogUrl}`}>
                      <span className="font-size-7">{ vacancy.Company } <span className="font-size-3">↗</span></span>
                    </Link>
                    {/* <img className="" height="40px" src={imgF1} alt="" /> */}
                  </div>
              </Col>
            </Row>
            <Row className="vacancy-details mb-16">
              <Col className="hspacer d-none d-lg-block col-lg-1"></Col>
              <Col className="content col-lg-7">
                <Row as="dl" className="job-parameters container d-flex px-0 m-0 justify-content-start">
                  {jobParams.map((p)=>(
                    <div className="col-6 col-sm-4 col-md-3 col-lg-4 mb-6 pl-0">
                      <dt>
                        <span className="param-dt">{p.label}</span>
                      </dt>
                      <dd>
                        <i className={`${p.icon} mr-3`}></i>
                        <span className="param-dd d-inline">{p.value}</span>
                      </dd>
                    </div>
                  ))}
                </Row>
              </Col>
              <Col className="col-12 col-lg-3 mt-10 mt-lg-0">
                <ApplyAndShare />
              </Col>
            </Row>
            <Row className="vacancy-details">
              <Col className="hspacer d-none d-lg-block col-lg-1"></Col>
              <Col className="content col-12 col-xl-7 col-lg-6 mb-10">
                <div className="job-details-content">
                    { JobDescription }
                </div>
              </Col>
              <Col className="apply-panel col-12 col-xl-3 col-lg-4 col-md-6 col-sm-9 sidebar">
              {
                isLoggedIn &&
                (
                  <Sticky top={120} bottomBoundary=".sidebar">
                    <Card className="rounded-4 border-primary text-center py-8 mb-10">
                      <Card.Body>
                        <div className="apply-info-text mb-8">
                          <h3 className="font-size-5 font-family-primary">
                            Do you want to know more?<br/>Reach out
                          </h3>
                        </div>
                        <div className="my-4 d-flex justify-content-center">
                          <GatsbyImage
                            image={avatar}
                            alt={vacancy.ContactPersonName}
                            style={{maxWidth: '140px'}}
                          />
                        </div>
                        <dl>
                          <dd>
                            <span className="contact-name  font-size-7">
                              {vacancy.ContactPersonName}
                            </span>
                            <br />
                            <span className="contact-company font-size-4">{vacancy.Company}</span>
                            <br />
                            <div className="contact-email mt-4 d-flex justify-content-center align-items-center">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-send-email`}>
                                      {
                                        <span>Send e-mail</span>
                                      }
                                  </Tooltip>
                                }
                              >
                                <a
                                  target="_blank"
                                  href={`mailto:${vacancy.ContactPersonEmail}?subject=COFRA Careers: ${vacancy.Position}`}
                                  className="d-inline-block text-black"
                                >
                                  <i className="fa fa-envelope font-size-7 mr-3"></i>
                                </a>
                              </OverlayTrigger>
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id={`tooltip-copy-email`}>
                                      { showTooltip ?
                                          <span>Copied</span> :
                                          <span>Copy e-mail address to clipboard</span>
                                      }
                                  </Tooltip>
                                }
                              >
                                <a href="#"
                                  className="d-inline-block text-black ml-6"
                                  onClick={(e)=>{
                                      e.preventDefault();
                                      handleCopyToClipboard(vacancy.ContactPersonEmail, setShowTooltip)
                                  }}>
                                  <i className="fa fa-copy"></i>
                                </a>
                              </OverlayTrigger>
                            </div>
                          </dd>
                        </dl>
                        <div className="apply-privacy-text mt-8 font-size-4">
                          <Link to="/cookies-and-privacy">
                            <i className="fas fa-info-circle mr-2"></i>
                            How is my privacy handled?
                          </Link>
                        </div>
                      </Card.Body>
                    </Card>
                    <ApplyAndShare />
                  </Sticky>
                )
              }
              </Col>
            </Row>
          </Container>
        </VacancyDetailContainer>
      </PageWrapper>
    </>
  );
};
export default VacancyDetails;

export const query = graphql`
  query vacancyQuery($slug: String!, $avatar: String!) {
    sharePointVacanciesList( data: { fields: { slug: {eq: $slug} } } )
    {
      data {
        fields {
          id
          Title
          JobPostID
          ContactPersonEmail
          ContactPersonName
          Created
          Experience
          Function
          ShortDescription
          JobDescription
          JobType
          Office
          Position
          VacancyClosingDate
          WorkingHours
          Company
          slug
        }
      }
    }
    avatar: file( relativePath: { eq: $avatar } ) 
    {
        childImageSharp {
          gatsbyImageData(
            quality: 80
            width:300
            height: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: CONSTRAINED
          )
        }
    }
  }
`