import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Modal, Button, InputGroup, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import Switch from "../Core/Switch";
import SectionSeparator from "../Core/SectionSeparator";
import ModalCloseButton from "../ModalCloseButton";
import iconWhatsapp from "../../assets/image/svg/icon-whatsapp.svg";
import iconEvelope from "../../assets/image/svg/icon-envelope.svg";
import iconLinkedIn from "../../assets/image/svg/icon-linkedin.svg";
import iconTelegram from "../../assets/image/svg/icon-telegram.svg";
import theme from "../../utils/theme";
import * as copy from 'copy-to-clipboard';
import { referral } from "../../hooks/useReferral";
import { useContent } from "../../hooks/useContent";
import UserContext from "../../context/UserContext";

const sharers = [
  {
    name: "email",
    btnClasses: "_gtm_share_vacancy_via_email",
    src: iconEvelope,
    alt: "Icon of envelope, share via email",
    width: 40,
    tooltip: 'Share via e-mail',
    disabled: (shareWithToken) => {
      return 'd-block'
    },
    href: (vacancyPosition, shareUrl, vacancyEntity) => {
      const url = new URL("mailto:")
      url.searchParams.set('subject', 'Here is a vacancy that might interest you!')
      url.searchParams.set('body', 
      `Hi, 
      
      I'd like to refer you for ${vacancyPosition} vacancy at ${vacancyEntity}. 
      Take a look and see if you're suitable for the position! 
      
      Go to ${shareUrl}`)
      return url.href.replaceAll('+', '%20')
    }
  },
  {
    name: "whatsapp",
    btnClasses: "_gtm_share_vacancy_via_whatsapp",
    src: iconWhatsapp,
    alt: "Icon of Whatsapp, share via Whatsapp",
    width: 32,
    tooltip: 'Share via WhatsApp web or desktop app',
    disabled: (shareWithToken) => {
      return 'd-block'
    },
    href: (vacancyPosition, shareUrl, vacancyEntity) => {
      const url = new URL("https://api.whatsapp.com/send")
      url.searchParams.append('text', 
      `Hi, I'd like to refer you for ${vacancyPosition} vacancy at ${vacancyEntity}. Take a look and see if you're suitable for the position! Go to ${shareUrl}`)
      return url.href.replaceAll('+', '%20')
    }
  },
  {
    name: "telegram",
    btnClasses: "_gtm_share_vacancy_via_telegram",
    src: iconTelegram,
    alt: "Telegram icon, share via Telegram",
    width: 32,
    tooltip: 'Share via Telegram web or desktop app',
    disabled: (shareWithToken) => {
      return 'd-block'
    },
    href: (vacancyPosition, shareUrl, vacancyEntity) => {
      const url = new URL("https://t.me/share/url")
      url.searchParams.append('url', shareUrl)
      url.searchParams.append('text', 
      `Hi, I'd like to refer you for ${vacancyPosition} vacancy at ${vacancyEntity}. Take a look and see if you're suitable for the position! Go to ${shareUrl}`)
      return url.href.replaceAll('+', '%20')
    }
  },
  {
    name: "linkedin",
    btnClasses: "_gtm_share_vacancy_via_linkedin",
    src: iconLinkedIn,
    alt: "Icon of LinkedIn, share as a LinkedIn post",
    width: 32,
    tooltip: 'Share as LinkedIn post.',
    disabled: (shareWithToken) => {
      return shareWithToken ? 'd-none' : 'd-block'
    },
    href: (vacancyPosition, shareUrl, vacancyEntity) => {
      const url = new URL("https://www.linkedin.com/sharing/share-offsite")
      // remove referral token
      const sUrl = new URL(shareUrl)
      sUrl.searchParams.delete(referral.tokenUrlParam)
      url.searchParams.append('url', sUrl.href)
      url.searchParams.append('title', `${vacancyPosition} vacancy at ${vacancyEntity}`)
      return url.href.replaceAll('+', '%20')
    }
  },
]

const ModalShare = (props) => {
  const { show, handleClose, vacancyUrl, vacancyPosition, vacancyEntity } = props;
  const [modalTab, setModalTab] = useState(0)
  const [shareWithToken, setShareWithToken] = useState(true)
  const [shareUrl, setShareUrl] = useState(vacancyUrl)
  const [isCopied, setIsCopied] = useState(false)

  const content = useContent()
  
  const user = useContext(UserContext)
  const entityLabel = user.get('companyLabel')
  const ReferralToken = user.get('aar_token', 'referral_token')
  
  useEffect(() => {
    if (shareWithToken) {
      const url = new URL(vacancyUrl)
      url.searchParams.set(referral.tokenUrlParam, ReferralToken)
      setShareUrl(url.href)
    } else {
      setShareUrl(vacancyUrl)
    }
  }, [shareWithToken])

  const handleCopy = () => {
    copy(shareUrl)
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000)
  }

  const myHandleClose = () => {
    setModalTab(0)
    handleClose()
  }

  const switchTabs = (tab, e) => {
    e.preventDefault()
    setModalTab(tab)
  }

  return (
    <Modal
      {...props}
      size="md"
      centered
      show={show}
      onHide={myHandleClose}
    >
      <ModalCloseButton onClick={myHandleClose} />
      <Modal.Body className="p-0">
        <div className="share-modal-main bg-white rounded-8 overflow-hidden">
          <div className={`tab-0 ${modalTab == 0 ? 'd-block' : 'd-none' }`}>
            <Container className="px-10 py-10">
              <Row>
                <Col className="col-2">
                  <Switch
                    defaultState={shareWithToken}
                    bg="primary"
                    className="ml-0"
                    onClick={() => setShareWithToken(!shareWithToken)}
                  />
                </Col>
                <Col className="col-10">
                  {content.get('share-vacancy-dialog', true)}
                  <div>
                    <Button 
                      variant="link"
                      className="p-0 m-0 font-size-4" 
                      href="#" 
                      onClick={(e) => switchTabs(1, e)}
                      style={{textTransform:"none"}}
                    >
                      { content.get('share-vacancy-dialog-learn-more-button', true) }
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
            <SectionSeparator />
            <Container className="px-10 py-10">
              <Row>
                <Col>
                  <div className="d-flex justify-content-between">
                    <div><b>Share:</b></div>
                    { sharers.map( s =>
                      <div className={`sharer-${s.name} mx-4 ${s.disabled(shareWithToken)}`}>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-share-${s.name}`}>
                                { 
                                  <span>{s.tooltip}</span>
                                }
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="link"
                            target="_blank"
                            className={`${s.btnClasses} ${shareWithToken ? '_gtm_referral_token_on' : ''} p-0`}
                            href={s.href(vacancyPosition, shareUrl, vacancyEntity)}
                          >
                            <img src={s.src} alt={s.alt} width={`${s.width}px`} />
                          </Button>
                        </OverlayTrigger>
                      </div>
                    ) }
                  </div>
                </Col>
              </Row>
              <Row className="mt-10">
                <Col>
                  <div className="mb-5"><b>Copy vacancy link</b></div>
                  <InputGroup>
                    <Form.Control
                      disabled
                      aria-label="Link to vacancy"
                      value={shareUrl}
                      style={{height:"initial", backgroundColor: "white", borderColor: theme.colors["primary"]}}
                    />
                    <Button
                      className={`_gtm_copy_vacancy_link ${shareWithToken ? '_gtm_referral_token_on' : ''}`}
                      onClick={handleCopy}
                      style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                    >
                      {isCopied ? 'Copied' : 'Copy'}
                    </Button>
                  </InputGroup>
                </Col>
              </Row>
            </Container>
          </div>
          <div className={`tab-1 ${modalTab == 1 ? 'd-block' : 'd-none' }`}>
            <Container className="px-10 py-10">
              <Row>
                <Col>
                  <Button 
                    variant="link"
                    className="p-0 m-0 font-size-4" 
                    href="#" 
                    onClick={(e) => switchTabs(0, e)}
                    style={{textTransform:"none"}}
                  >
                    <i className="fas fa-arrow-left mr-4"></i> Back
                  </Button>
                </Col>
              </Row>
              <Row className="mt-10">
                <Col>
                  { content.get(`share-vacancy-dialog-read-more-${entityLabel}`, false) }
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalShare;
