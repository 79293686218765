import React from 'react'
import { api } from '../../utils/api'

class GreenhouseApplicationForm extends React.Component {

    constructor(props) {
        super(props)
        const {vacancy, setLoaded} = this.props
        this.state = { scriptLoaded: false }
        this.setLoaded = setLoaded
        this.job_post_id = vacancy.JobPostID
        this.job_id = vacancy.Title
        this.portal_job_id = vacancy.id
        this.vacancy_publisher = vacancy.Company
        this.gh_src = process.env.GATSBY_GREENHOUSE_BOARD_SOURCE_TOKEN

        // get referral token from URL
        const url = new URL(window?.location.href)
        this.rt = url.searchParams.get('rt')

        this.script=document.createElement('script')
        this.script.src=`https://boards.greenhouse.io/embed/job_board/js?for=${process.env.GATSBY_GREENHOUSE_BOARD_TOKEN}`
        this.script.async=true;
    }
    
    componentDidMount() {

        this.scriptEl.appendChild(this.script)

        // on script load success
        this.script.addEventListener("load", this.handleScriptLoaded.bind(this));

        // on script load failed
        this.script.addEventListener("error", this.handleScriptLoadError.bind(this));

        // Listen to greenhouse messages
        window.addEventListener('message', this.handleMessage.bind(this), false);
    }

    componentWillUnmount() {
        this.script.removeEventListener("load", this.handleScriptLoaded.bind(this));
        this.script.removeEventListener("error", this.handleScriptLoadError.bind(this));
        window.removeEventListener('message', this.handleMessage.bind(this), false);
    }
    
    handleScriptLoaded(e) {
        // Do we have Grnhse?
        try {
            window.Grnhse.Iframe.load(this.job_post_id, this.gh_src)
            this.setState({scriptLoaded: true})
            this.setLoaded(true)
            
            // TODO
            // What if recruiter deletes the job and portal is not yet updated?
            // We need to check if the job still exists
            // Greenhouse doesn't issue any message on successful job loaded.
            // If job load fails, iframe says "sorry but we can't find that page".
            // For now, let's assume that we always get the job loaded.

        } catch (error) {
            // display error message
            console.log(error)
        }
    }

    handleMessage(e) {
        // on application submit success
        if (
            this.state.scriptLoaded 
            &&
            e.origin === window.Grnhse.Settings.targetDomain 
            && 
            e.data === 'greenhouse.confirmation'
        ) {
            // Log application
            api('log/application', {
                    "applied_timestamp": new Date().toISOString(),
                    "portal_job_id": parseInt(this.portal_job_id),
                    "job_post_id": this.job_post_id,
                    "job_id": this.job_id,
                    "referral_token": this.rt || '',
                    "integration": 'greenhouse_cofra'
                }
            )
            // Trigger google analytics
            const dataLayer = window.dataLayer || [];
            const event = {
                event: 'vacancy_employee_applied_success',
                vacancy_publisher: this.vacancy_publisher
            }
            dataLayer.push(event)
        }
    }

    handleScriptLoadError (e) {
        // display error message
        console.log('failed to load script:', e)
    }

    render() {
        return(
            <>
                <div id="grnhse_app"></div>
                <div ref={el => (this.scriptEl = el)} ></div>
            </>
        )
    }
}

export default GreenhouseApplicationForm