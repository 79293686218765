import React, { useState } from "react";
import { Row, Col, Modal, Spinner } from "react-bootstrap";
import GreenhouseForm from "../Greenhouse/ApplicationForm"
import ModalCloseButton from "../ModalCloseButton";

const ModalApplication = (props) => {
  const { show, handleClose, vacancy } = props;
  const entity = vacancy.Company
  const [loaded, setLoaded] = useState(false);

  const SpinnerDiv = (props) => {
    return (
      <div className="d-flex p-6 justify-content-center">
        <Spinner 
          animation="border" role="status">
          <span className="visually-hidden invisible">Loading...</span>
        </Spinner>  
      </div>
    )
  }

  return (
    <Modal
      {...props}
      size="md"
      centered
      show={show}
      onHide={handleClose}
    >
      <ModalCloseButton onClick={handleClose} />
        <div>
          <Modal.Header className="justify-content-center">
            <i className="fa fa-info-circle mr-4"></i> This job application is operated by {entity}.
          </Modal.Header>
          <Modal.Body className="p-0">
            { loaded ? null : <SpinnerDiv /> } 
            <div className="apply-modal-main bg-white rounded-8 overflow-hidden">
              <Row className="row no-gutters">
                <Col>
                  <div style={{transform: 'translateY(-0px)'}}>
                    <GreenhouseForm vacancy={vacancy} setLoaded={setLoaded}/>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal.Body>
        </div>
    </Modal>
  );
};

export default ModalApplication;
